.right-view {
  display: none;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  background-color: white;
  z-index: 500;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  padding: 20px 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);

  .right-view-header {
    margin: -20px -20px 20px -20px;
    padding: 15px;
  }

  .right-view-body {
    height: 100%;
    position: relative;
    overflow-x: hidden;
    overflow-y: scroll;
    scrollbar-width: none;
  }

  .right-view-footer {
    padding-top: 15px;
  }
}

.drop-shadow {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: -180deg;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-blur: blur(75px);
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-drop-shadow: ;

  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast)
    var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate)
    var(--tw-sepia) var(--tw-drop-shadow);

  background-image: linear-gradient(
    89deg,
    rgba(255, 118, 223, 0.2),
    rgba(144, 88, 255, 0.2) 51.3%,
    rgba(0, 205, 250, 0.2)
  );
  border-radius: 9999px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  height: 1500px;
  top: -1450px;
  position: absolute;
}

.stripeForce > div {
  width: 100% !important;
}
